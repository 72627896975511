import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { BorderedBox, GrayBoxForDetails } from "./whyUsStyles";
import CustomLineChart from "../Common/Charts/CustomLineChart";
import CustomToggleButton from "../Common/CustomToggleButton";
import { useEffect, useState } from "react";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { decimalFreeNumber, formatNumber } from "../../Utils/utils";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

const FinancialImpact = ({
	id,
	financialPayment,
	financialSavings,
}: {
	id?: string;
	financialPayment?: any;
	financialSavings?: any;
}) => {
	const colors = useSelector(selectColors);

	const textSnippets = useSelector(selectAllTextSnippets);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const financialOptions = {
		PAYMENT: { text: textSnippets["financial_option_payment"] || "Payment", value: 1 },
		SAVING: { text: textSnippets["financial_option_saving"] || "Saving", value: 2 },
	};

	const [option, setOption] = useState<number | string>(financialOptions.PAYMENT.value);
	const [paymentData, setPaymentData] = useState<any>(financialPayment);
	const [savingsData, setSavingsData] = useState<any>(financialSavings);

	useEffect(() => {
		setPaymentData(financialPayment);
		setSavingsData(financialSavings);
	}, [financialPayment, financialSavings]);

	const paymentValues = [
		{
			value: paymentData?.monthlyBill,
			description: textSnippets["current_month_utility_bill"] || "Current month utility bill",
			isLoss: true,
		},
		{
			value: paymentData?.SolarSavingMonth,
			description: textSnippets["month_solar_payment"] || "Month solar payment",
			isProfit: true,
		},
		{
			value: paymentData?.UtilityBillAfterSolar,
			description: textSnippets["utility_bill_after_solar"] || "Utility bill after solar",
		},
	];

	const savingValues = [
		{
			value: decimalFreeNumber(savingsData?.costOfElectricityWithoutSolar),
			description: textSnippets["saving_plan_loss_desc"] || "Cost of doing nothing",
			isLoss: true,
		},
		{
			value: decimalFreeNumber(savingsData?.totalSavings20Years) as any,
			description: textSnippets["saving_plan_profit_desc"] || "Total estimated solar saving",
			isProfit: true,
		},
	];

	const paymentsArray = paymentData?.TotalSolarSavings20years ?? [];
	const energyData = paymentsArray?.map((item: any) => ({ value: item?.MonthlyBill, label: item?.year })) ?? [];
	const solarData = paymentsArray?.map((item: any) => ({ value: item?.SolarSavingMonth, label: item?.year })) ?? [];
	const residualData =
		paymentsArray?.map((item: any) => ({ value: item?.ResidualElectricBill, label: item?.year })) ?? [];
	const savingsChartData =
		savingsData?.savingsArray?.map((item: any) => ({ value: item?.SolarSavingYear, label: item?.year })) ?? [];

	const chartDataForPayment = [solarData, energyData, ...(paymentData?.UtilityBillAfterSolar ? [residualData] : [])];
	const chartDataForSavings = [savingsChartData, []];

	const isPaymentSelected = option === financialOptions.PAYMENT.value;

	return (
		<Box id={id}>
			<Typography variant={isSmallScreen ? "h3" : "h2"} mt={{ xs: 24, sm: 60 }} mb={10}>
				{textSnippets["financial_impact_heading"] || "Financial Impact"}
			</Typography>
			<Typography fontSize={16} mb={20} maxWidth={620}>
				{textSnippets["financial_impact_desc"] ||
					"	Financial Impact means either a decrease in the value of the assets or an increase in the losses and liabilities pertaining to the assets,"}
			</Typography>
			<BorderedBox sx={{ border: `1px solid ${colors.border}`, ...(isSmallScreen ? { padding: "24px 16px" } : {}) }}>
				<Box display="flex" justifyContent="center" mb={{ xs: 24, sm: 48 }} className="buttons-to-hide-in-pdf">
					<CustomToggleButton
						value={option}
						onChange={(val) => setOption(val)}
						options={Object.values(financialOptions)}
					/>
				</Box>

				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection={{ xs: "column", sm: "row" }}
					gap={{ xs: 12, sm: 32 }}
					mb={42}
				>
					{(isPaymentSelected ? paymentValues : savingValues).map((item, idx) => (
						<GrayBoxForDetails key={idx} sx={{ backgroundColor: colors.lightGray }}>
							<Typography
								variant="h2"
								fontSize={40}
								color={item.isLoss ? "error.dark" : item.isProfit ? "success.main" : ""}
							>
								${formatNumber(item.value || 0)}
							</Typography>
							<Typography fontSize={16}>{item.description}</Typography>
						</GrayBoxForDetails>
					))}
				</Box>

				{isPaymentSelected && (
					<Box display="flex" alignItems="center" gap={8} mb={32}>
						<Box sx={{ height: 19, width: 19, borderRadius: borderRadius.xs, bgcolor: colors.errorLight }} />
						<Typography fontSize={16} mr={14}>
							Energy
						</Typography>
						<Box sx={{ height: 19, width: 19, borderRadius: borderRadius.xs, bgcolor: colors.success }} />
						<Typography fontSize={16}>Solar</Typography>
					</Box>
				)}

				<CustomLineChart
					fill
					data={isPaymentSelected ? chartDataForPayment : chartDataForSavings}
					solidSecondColor={isPaymentSelected}
				/>
			</BorderedBox>
		</Box>
	);
};

export default FinancialImpact;
