import { AppBar, Box, styled } from "@mui/material";

export const StyledFooter = styled(AppBar)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: 12,
	backgroundColor: "white",
	marginLeft: "auto",
	width: "max-content",
	marginRight: "80px",
	// width: { xs: "100%", sm: "max-content" },
	// marginRight: { xs: "0px", sm: "61px" },

	// boxShadow: "none",
	// color: colors.text,
	// borderTop: `1px solid ${colors.border}`,
	padding: "12px 12px",
	borderRadius: "16px",
	position: "sticky",
	bottom: 28,
	zIndex: 12,
	boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

	[theme.breakpoints.down("sm")]: {
		bottom: 0,
		padding: "12px 16px",
		alignItems: "center",
		borderRadius: "0px",

		width: "100%",
		marginRight: "0px",
	},
}));

export const StyledMenuBlock = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	gap: 24,
	color: "inherit",
	[theme.breakpoints.down("sm")]: {
		gap: 12,
	},
}));
