import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import Navbar from "../Navbar/Navbar";
import { LoginContainer, LoginLeftBlock, LoginRightBlock } from "./loginStyles";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import LoginPageImage from "../../Assets/Images/login-page-image.png";
import CustomTextField from "../Common/CustomTextField";
import CustomCheckBox from "../Common/CustomCheckBox";
import * as EmailValidator from "email-validator";
import { toast } from "react-toastify";
import { loginUser } from "../../Services/userService";
import Loader from "../Common/Loader";
import CustomButton from "../Common/CustomButton";
import { useDispatch } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import GoogleLoginButton from "./GoogleLoginButton";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import FacebookLoginButton from "./FacebookLoginButton";
import { getAssignedRepresentative, getProfile } from "../../Services/profileService";
import { requestNotificationPermission } from "../../firebase";
import VerifyEmailDialog from "../SignUp/VerifyEmailDialog";

interface DataProps {
	email: string;
	password: string;
}

const Login = () => {
	const colors = useSelector(selectColors);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state }: any = useLocation();
	const newAccountCreated = state?.newAccountCreated;
	const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [data, setData] = useState<DataProps>({ email: "", password: "" });
	const [errors, setErrors] = useState<DataProps>({ email: "", password: "" });
	const [loading, setLoading] = useState<boolean>(false);
	const [rememberMe, setRememberMe] = useState<boolean>(false);
	const [forgotPasswordDialog, setForgotPasswordDialog] = useState<boolean>(false);
	const [verificationDialog, setVerificationDialog] = useState<boolean>(false);

	useEffect(() => {
		if (newAccountCreated) {
			openVerificationDialog();
		}
	}, []);

	const openVerificationDialog = () => setVerificationDialog(true);
	const closeVerificationDialog = () => {
		navigate(allRoutes.LOGIN);
		setVerificationDialog(false);
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.password = data.password ? "" : "Password cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleLoginSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const newUser: any = await dispatch(loginUser(data));

			if (newUser.access_token === "An Email sent to your account please verify") {
				toast.warn("An email has been sent to your account. Please check your inbox and verify your email address.");
			} else if (newUser) {
				await onLoginSuccess();
			} else {
				onLoginFailure();
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const onLoginSuccess = async () => {
		await dispatch(getProfile());
		await dispatch(getAssignedRepresentative());
		await requestNotificationPermission();
		toast.success("Successfully signed in!");
		navigate(allRoutes.WHY_CHOOSE_US);
	};

	const onLoginFailure = () => {
		toast.error("Something went wrong!");
	};

	const openForgotPasswordDialog = () => setForgotPasswordDialog(true);
	const closeForgotPasswordDialog = () => setForgotPasswordDialog(false);

	return (
		<>
			<Loader open={loading} />
			<Navbar />
			<VerifyEmailDialog open={verificationDialog} onClose={closeVerificationDialog} />
			<LoginContainer>
				<LoginLeftBlock>
					<Typography variant={isMobileView ? "h3" : "h2"}>Sign in to your account</Typography>
					<Typography mt={10} mb={31}>
						Sign in to your account
					</Typography>

					<GoogleLoginButton onSuccess={onLoginSuccess} onFailure={onLoginFailure} />
					<FacebookLoginButton onSuccess={onLoginSuccess} onFailure={onLoginFailure} />

					<div className="fb-like" data-share="true" data-width="450" data-show-faces="true"></div>

					<Divider sx={{ my: 32 }}>
						<Typography px={16} color={colors.textMid}>
							Or
						</Typography>
					</Divider>

					<form onSubmit={handleLoginSubmit}>
						<CustomTextField
							name="email"
							label="Email Address"
							bottom={24}
							value={data.email}
							error={errors.email}
							onChange={handleOnChange}
						/>
						<CustomTextField
							type="password"
							name="password"
							label="Password"
							bottom={19}
							value={data.password}
							error={errors.password}
							onChange={handleOnChange}
						/>
						<Box display="flex" alignItems="center" justifyContent="space-between" mb={30}>
							<CustomCheckBox text="Remember me" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
							<Typography className="link" onClick={openForgotPasswordDialog}>
								Forgot your password?
							</Typography>
						</Box>
						<CustomButton type="submit" fullWidth sx={{ mb: 20 }}>
							Sign In
						</CustomButton>
					</form>

					<Typography textAlign="center" mt="auto" pt={12}>
						Don't have an account?{" "}
						<span className="link" onClick={() => navigate(allRoutes.SIGN_UP)}>
							Create an Account
						</span>
					</Typography>
				</LoginLeftBlock>

				<LoginRightBlock>
					<Box
						className="purple-box"
						sx={{
							backgroundColor: colors.primaryExtraLight,
							"& .login-image": {
								border: `1px solid ${colors.border}`,
							},
						}}
					>
						<img className="login-image" src={LoginPageImage} alt="" />
						<img className="login-image img-2" src={LoginPageImage} alt="" />
						<img className="login-image img-3" src={LoginPageImage} alt="" />
					</Box>
				</LoginRightBlock>
			</LoginContainer>

			<ForgotPasswordDialog open={forgotPasswordDialog} onClose={closeForgotPasswordDialog} />
		</>
	);
};

export default Login;
