import { useEffect, useState } from "react";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { saveAddressObject, saveBill, savePostalCode, selectUser } from "../../Redux/Slices/userSlice";
import CustomButton from "../Common/CustomButton";
import { SolarReportContainer, GrayBlock, SolarReportRightColumn } from "./solarReportStyles";
import CustomDoughnutChart from "../Common/Charts/CustomDoughnutChart";
import UtilityBillIncreasing, { BillData } from "../WhyUs/UtilityBillIncreasing";
import BarWithValue, { DoubleBarsWithValue } from "./BarWithValue";
import DashedBoxWithValues from "./DashedBoxWithValues";
import BillBlock from "./BillBlock";
import { HomeImage, ReportIcon1, ReportIcon2, ReportIcon3, ReportIcon4 } from "../../Utils/Images";
import useScrollPercentage from "../../Hooks/useScrollPercentage";
import { toast } from "react-toastify";
import { getSolarReport } from "../../Services/reportService";
import Loader from "../Common/Loader";
import { decimalFreeNumber, formatNumber, getSatelliteImage, getStreetViewImage } from "../../Utils/utils";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import PDFDownloader from "../Common/PDFDownloader";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import CustomCheckBox from "../Common/CustomCheckBox";
import QualificationDialog from "./QualificationDialog";
import { decodeReferralLink } from "../../Services/userService";
import { verifyAddress } from "../../Services/addressService";
import { useDispatch } from "../../Redux/reduxHooks";
import { HelloBlock } from "../WhyUs/WelcomeBlock";

const SolarReport = ({ forDashboard, dashboardData }: { forDashboard?: boolean; dashboardData?: any }) => {
	const colors = useSelector(selectColors);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const scrollPercentage = useScrollPercentage();
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
	const [searchParams] = useSearchParams();

	// TODO in future: create PageLayout and move Navbar in that component and also move page padding in that as well and handle responsive padding there

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [yearlyBills, setYearlyBills] = useState<Array<BillData>>([]);
	const [data, setData] = useState<any>({});
	// const [options, setOptions] = useState({
	// 	noCost: false,
	// 	handleDesign: false,
	// 	monitoring25years: false,
	// 	energyGuarantee: false,
	// });

	// Fetching Text Snippets From Redux
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		window.scrollTo(0, 0);

		const referralLink = searchParams.get("referral-link");
		if (referralLink) {
			getDataOfReferralLink(referralLink);
		} else if ((!user.address || !user.bill) && !forDashboard) {
			navigate(allRoutes.ESTIMATE);
		} else {
			fetchSolarReportData();
		}
	}, [dashboardData]);

	const getDataOfReferralLink = async (referralLink: string) => {
		if (referralLink) {
			setLoading(true);
			try {
				const { data: decoded } = await decodeReferralLink(referralLink);
				console.log("Decoded Link:", decoded);

				if (decoded?.address) {
					const { data }: any = await verifyAddress(decoded?.address ?? "");

					dispatch(
						saveAddressObject({
							description: decoded.address,
							structured_formatting: {
								main_text: decoded.address,
								secondary_text: decoded.address,
							},
						}),
					);
					dispatch(saveBill(decoded.bill));

					if (
						[
							"We are not providing services for this address yet. Please try again in future.",
							"Not a US address",
							"Something went wrong",
						].includes(data)
					) {
						toast.warn(
							"We are sorry, but we are currently not operating in your area. Please try again in a few months.",
						);
						navigate(allRoutes.ESTIMATE);
					} else if (data.isValid) {
						// storing address and bill in redux for future use

						dispatch(savePostalCode(data.postalCode as string));

						await fetchSolarReportData();
					} else {
						toast.error("Enter a valid address!");
						navigate(allRoutes.ESTIMATE);
					}
				}
			} catch (error: any) {
				toast.error(error);
			}
			setLoading(false);
		}
	};

	const fetchSolarReportData = async () => {
		console.log("USER", user);
		if (forDashboard) {
			return handleSetValues(dashboardData);
		}

		setLoading(true);
		try {
			const { data: response } = await getSolarReport(user.bill || "200", user.postalCode as string);

			handleSetValues(response);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleSetValues = (response: any) => {
		setData(response);
		setYearlyBills(response?.yearlyBills || []);
	};

	const handleNext = () => {
		// navigate(allRoutes.SIGN_UP);
		openDialog();
	};

	const openDialog = () => setShowDialog(true);
	const closeDialog = () => setShowDialog(false);

	const maxHeightOfBlock = data?.UtilityBillAfterSolar > 0 ? 360 : 300;
	const heightOfElectricBlock =
		(data?.totalAnnualBill20years /
			(data?.totalAnnualBill20years +
				(data?.UtilityBillAfterSolar > 0 ? data?.totalResidualBill25Years : 0) +
				data?.switchToSolar || 1)) *
			maxHeightOfBlock || 250;
	const heightOfSavingsBlock =
		(data?.switchToSolar /
			(data?.totalAnnualBill20years +
				(data?.UtilityBillAfterSolar > 0 ? data?.totalResidualBill25Years : 0) +
				data?.switchToSolar || 1)) *
			maxHeightOfBlock || 250;
	const heightOfResidualBlock =
		((data?.totalResidualBill25Years /
			(data?.totalAnnualBill20years + data?.switchToSolar + data?.totalResidualBill25Years || 1)) *
			maxHeightOfBlock || 250) + heightOfSavingsBlock;

	const displayFirstElement = isSmallScreen ? true : scrollPercentage < (forDashboard ? 20 : 33);
	const displaySecondElement = isSmallScreen
		? true
		: scrollPercentage >= (forDashboard ? 20 : 33) && scrollPercentage < (forDashboard ? 40 : 66);
	const displayThirdElement = isSmallScreen ? true : scrollPercentage >= (forDashboard ? 40 : 66);
	// const displayFirstElement = isSmallScreen ? true : scrollPercentage < 33;
	// const displaySecondElement = isSmallScreen ? true : scrollPercentage >= 33 && scrollPercentage < 66;
	// const displayThirdElement = isSmallScreen ? true : scrollPercentage >= 66;

	// const annualBill1month = data?.billAfter1Year ?? 0;
	// const annualBill20years = data?.monthlyBill20Years * 12 ?? 0; // TODO: will handle it later on BE side
	// const annualBill20years = data?.billAfter20Years ?? 0;

	const doughnutChartData = [
		{ value: data?.SolarOffset, label: "Solar Usage", color: colors.primary },
		{ value: data?.UtilityUsage, label: "Utility Usage", color: "white" },
	];

	return (
		<>
			{!forDashboard && <Navbar />}
			<Loader open={loading} />

			<SolarReportContainer id="summary-report" forDashboard={forDashboard}>
				<Box>
					{/* {forDashboard && isSmallScreen ? ( */}
					{forDashboard ? (
						<HelloBlock user={user} />
					) : (
						<>
							<Typography variant="h4">{textSnippets.solar_appeal_message || "Solar Looks Good On You!"}</Typography>
							<Divider sx={{ mt: { xs: 12, sm: 20 } }} />
						</>
					)}
					<Typography variant="h5" mb={10} mt={24}>
						{textSnippets.your_house_label || "Your House"}
					</Typography>
					{/* <img
						className="left-image"
						src={isSmallScreen ? getStreetViewImage(user.address) : getSatelliteImage(user.address)}
						alt=""
					/> */}
					<img className="left-image" src={getStreetViewImage(user.address) || HomeImage} alt="" />
					<Typography variant="body2" mt={10}>
						{user.address || "Your house address"}
					</Typography>
					<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
						{textSnippets.electric_bill_label || "Your Current Bill"}
					</Typography>

					<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
						{isSmallScreen ? (
							<>
								<Box display="flex" alignItems="center" justifyContent="space-between" gap={10}>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.current_monthly_bill || "Monthly Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(user.bill || data?.monthlyBill)}
										</Typography>
									</Box>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.yearly_electricity_expense || "Annual Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.billAfter1Year)}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.estimated_25yr_electricity || "25 Years Expense"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="error.main">
										${formatNumber(data?.billAfter20Years)}
									</Typography>
								</Box>
							</>
						) : (
							<>
								<Box display="flex" alignItems="center" justifyContent="space-between" gap={10}>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{"Monthly Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(user.bill || data?.monthlyBill)}
										</Typography>
									</Box>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.yearly_electricity_expense || "Annual Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.billAfter1Year)}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.estimated_25yr_electricity || "25 Years Expense"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="error.main">
										${formatNumber(data?.billAfter20Years)}
									</Typography>
								</Box>
							</>
							// <>
							// 	<Typography variant="h6" fontWeight={600}>
							// 		{textSnippets.current_monthly_bill || "Current monthly bill"}
							// 	</Typography>
							// 	<Typography variant="h2" fontWeight={500} mt={16} mb={8}>
							// 		${formatNumber(user.bill || data?.monthlyBill)}
							// 	</Typography>

							// 	<BillBlock
							// 		hideIcon
							// 		title={textSnippets.yearly_electricity_expense || "Your yearly electricity expense"}
							// 		bill={formatNumber(data?.billAfter1Year)}
							// 		isLoss
							// 	/>

							// 	<Typography variant="h6" fontWeight={600} mt={26} mb={19}>
							// 		{textSnippets.estimated_25yr_electricity || "25 years Electricity Expense"}
							// 	</Typography>
							// 	<BillBlock isLoss isCenterAligned title="" bill={formatNumber(data?.billAfter20Years)} />
							// </>
						)}
						{/* <BillBlock isLoss title="" bill={formatNumber(data?.billAfter20Years)} /> */}
						{/* <Typography mt={12} mb={20} color="text.secondary">
							{textSnippets.steady_increase_warning || "Modest but steady increase over time leads to huge expenses"}
						</Typography> */}
					</GrayBlock>

					{isSmallScreen && (
						<>
							<GrayBlock sx={{ backgroundColor: colors.lightGray }} my={16}>
								<UtilityBillIncreasing mt={0} yearlyBills={yearlyBills} />
							</GrayBlock>
							<Box
								component="img"
								className="left-image"
								src={(isSmallScreen ? getSatelliteImage(user.address) : getStreetViewImage(user.address)) || HomeImage}
								alt=""
								sx={{ height: "173px !important" }}
							/>
						</>
					)}

					<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={18}>
						<Typography variant="h6" fontWeight={600}>
							{textSnippets.solar_system_label || "Your Solar System"}
						</Typography>
						<Typography variant="h2" fontWeight={500} mt={16} mb={isSmallScreen ? 16 : 24}>
							{data?.SystemSizeKW?.toLocaleString()}kW Solar
						</Typography>
						{/* <Typography mb={6} color="text.secondary">
							{textSnippets.solar_system_power_desc ||
								"This is the power of your solar system, sometimes referred to as the system size"}
						</Typography> */}
						{isSmallScreen ? (
							<Box display="flex" alignItems="center" justifyContent="space-between" gap={6}>
								<Typography fontWeight={500}>{textSnippets.system_production || "Producing"}</Typography>
								<Typography color="success.main">{formatNumber(data?.systemProduction)} kW/h</Typography>
							</Box>
						) : (
							// <BillBlock
							// 	hideIcon
							// 	title={textSnippets.system_production || "System production"}
							// 	bill={formatNumber(data?.systemProduction)}
							// 	customUnit=" kW/h"
							// />
							<Box display="flex" alignItems="center" justifyContent="space-between" gap={6}>
								<Typography fontWeight={500}>{textSnippets.system_production || "Producing"}</Typography>
								<Typography color="success.main">{formatNumber(data?.systemProduction)} kW/h</Typography>
							</Box>
						)}

						<Typography variant="h6" fontWeight={600} mt={isSmallScreen ? 32 : 14} mb={36}>
							{textSnippets.electricity_usage_label || "Electricity Usage"}
						</Typography>

						<Box width={192} mx={"auto"}>
							<CustomDoughnutChart displayingValue={data?.SolarOffset} dataSets={doughnutChartData} />
						</Box>

						<Box display="flex" alignItems="center" justifyContent="space-between" mt={35} gap={10}>
							<Typography variant="h6" fontWeight={600}>
								{textSnippets.utility_usage_label || "Utility Usages"}: {formatNumber(data?.UtilityUsage)}%
							</Typography>
							<Typography variant="h6" fontWeight={600}>
								{textSnippets.solar_usage_label || "Solar Usages"}:{" "}
								<Typography variant="inherit" fontWeight="inherit" component="span" color="success.main">
									{formatNumber(data?.SolarOffset)}%
								</Typography>
							</Typography>
						</Box>

						{/* <BillBlock
							hideIcon
							title={
								<span>
									{formatNumber(data?.panelCount)} Q Cell 400 W Solar Panels <br />
									{formatNumber(data?.panelCount)} Enphase Micro Inverters
								</span>
							}
							mt={35}
						/> */}
						<Typography variant="h6" fontWeight={600} mt={32} mb={36}>
							{textSnippets.no_pocket_expenses || "No Out of Pocket Expenses"}
						</Typography>
						<Box
							display="flex"
							flexDirection="column"
							gap={20}
							sx={{
								"& svg": {
									"& path": {
										fill: colors.primary,
									},
								},
							}}
						>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon1 />
								{/* <Box component="img" src={ReportIcon1} /> */}
								<Typography variant="h6">We handle design, permits, installation</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon4 />
								{/* <Box component="img" src={ReportIcon4} /> */}
								<Typography variant="h6">25-year monitoring, maintenance, and repairs</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon2 />
								{/* <Box component="img" src={ReportIcon2} /> */}
								<Typography variant="h6">Energy Guarantee</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon3 />
								{/* <Box component="img" src={ReportIcon3} /> */}
								<Typography variant="h6">No out-of-pocket cost for all system components if qualified</Typography>
							</Box>
							{/* <CustomCheckBox
								disabled
								checked={options.noCost}
								onChange={() => setOptions({ ...options, noCost: !options.noCost })}
								text="No out-of-pocket cost for all system components if qualified"
							/> */}
							{/* <CustomCheckBox
								disabled
								checked={options.handleDesign}
								onChange={() => setOptions({ ...options, handleDesign: !options.handleDesign })}
								text="We handle design, permits, installation"
							/> */}
							{/* <CustomCheckBox
								disabled
								checked={options.monitoring25years}
								onChange={() => setOptions({ ...options, monitoring25years: !options.monitoring25years })}
								text="25-year monitoring, maintenance, and repairs"
							/>
							<CustomCheckBox
								disabled
								checked={options.energyGuarantee}
								onChange={() => setOptions({ ...options, energyGuarantee: !options.energyGuarantee })}
								text="Energy Guarantee"
							/> */}
						</Box>
						{/* <BillBlock
							hideIcon
							title={
								textSnippets.solar_service_description ||
								"We handle solar design, permitting and installation 25 year warranty includes maintenance and monitoring"
							}
						/> */}
					</GrayBlock>

					{isSmallScreen ? (
						<>
							<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
								{"With Solar"}
								{/* {textSnippets.electric_bill_label || "With Solar"} */}
							</Typography>

							<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									columnGap={10}
									rowGap={42}
									flexWrap={"wrap"}
									sx={{ "& > div": { width: "40%" } }}
								>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.monthly_bill_with_solar || "Monthly bill with solar"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.solarSavingMonth1)}
										</Typography>
									</Box>

									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{/* {textSnippets.first_year_savings || "First Year Saving"} */}
											{"Residual Electric Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.UtilityBillAfterSolar))}
										</Typography>
									</Box>

									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										gap={6}
										mx="auto"
										width="100% !important"
									>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.first_year_savings || "First Year Saving"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.solarSavingYear1))}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.avoided_25yr_expense || "Avoided Expenses over 25 years"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="success.main">
										${formatNumber(decimalFreeNumber(data?.solarSavingYear25))}
									</Typography>
								</Box>
							</GrayBlock>
						</>
					) : (
						// <>
						// 	<GrayBlock mt={18}>
						// 		<Typography variant="h6" fontWeight={600}>
						// 			{textSnippets.monthly_bill_with_solar || "Monthly bill with solar"}
						// 		</Typography>
						// 		<Typography variant="h2" fontWeight={500} mt={16} mb={8}>
						// 			${formatNumber(data?.solarSavingMonth1)}
						// 		</Typography>
						// 		<Typography fontSize={16} fontWeight={600} mt={20} mb={14}>
						// 			{/* {textSnippets.fixed_solar_bill || "Your solar bill is fixed and will not increase"} */}
						// 			{textSnippets.first_year_savings || "Your first year solar savings"}
						// 		</Typography>

						// 		<BillBlock
						// 			hideIcon
						// 			// title={textSnippets.first_year_savings || "Your first year solar savings"}
						// 			bill={formatNumber(decimalFreeNumber(data?.solarSavingYear1))}
						// 			isCenterAligned
						// 		/>
						// 		{/* <BillBlock
						// 	hideIcon
						// 	title={textSnippets.estimated_10yr_savings || "Estimated 10 year solar savings"}
						// 	bill={formatNumber(decimalFreeNumber(data?.solarSavingYear10))}
						// 	mt={8}
						// /> */}
						// 	</GrayBlock>

						// 	<GrayBlock mt={18}>
						// 		<Typography variant="h6" fontWeight={600} mb={18}>
						// 			{textSnippets.avoided_25yr_expense || "Avoided Electricity Expense over 25 years"}
						// 		</Typography>
						// 		<BillBlock bill={formatNumber(decimalFreeNumber(data?.solarSavingYear25))} isCenterAligned />
						// 		<Typography mt={12} color="text.secondary">
						// 			{textSnippets.increasing_savings_warning ||
						// 				"Modest but steadily increasing savings over time lead to huge cash flows"}
						// 		</Typography>
						// 	</GrayBlock>
						// </>
						<>
							<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
								{"With Solar"}
								{/* {textSnippets.electric_bill_label || "With Solar"} */}
							</Typography>

							<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									columnGap={10}
									rowGap={42}
									flexWrap={"wrap"}
									sx={{ "& > div": { width: "40%" } }}
								>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.monthly_bill_with_solar || "Monthly bill with solar"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.solarSavingMonth1)}
										</Typography>
									</Box>

									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{/* {textSnippets.first_year_savings || "First Year Saving"} */}
											{"Residual Electric Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.UtilityBillAfterSolar))}
										</Typography>
									</Box>

									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										gap={6}
										mx="auto"
										width="100% !important"
									>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.first_year_savings || "First Year Saving"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.solarSavingYear1))}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.avoided_25yr_expense || "Avoided Expenses over 25 years"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="success.main">
										${formatNumber(decimalFreeNumber(data?.solarSavingYear25))}
									</Typography>
								</Box>
							</GrayBlock>
						</>
					)}

					{/* <BillBlock
						hideIcon
						title={`Avoid $${formatNumber(decimalFreeNumber(data?.solarSavingYear25))} in grid charges by going solar`}
						mt={19}
						mb={forDashboard ? 0 : 27}
					/> */}

					{!isSmallScreen &&
						(!forDashboard ? (
							<CustomButton fullWidth onClick={handleNext} sx={{ mt: 24 }}>
								{textSnippets.unlock_proposal_button || "See if I qualify"}
							</CustomButton>
						) : (
							<Box>
								{/* <Box mt={24} className="buttons-to-hide-in-pdf" />
								<PDFDownloader
									isSummaryPage
									buttonText={textSnippets.download_proposal_button || "Download Summary Proposal"}
									elementId="summary-report"
								/> */}
							</Box>
						))}
				</Box>
				{!isSmallScreen && (
					<SolarReportRightColumn
						id="right-col"
						forDashboard={forDashboard}
						sx={{ backgroundColor: colors.primaryExtraLight }}
					>
						{displayFirstElement && (
							<Box
								className="fade-in-block"
								sx={{
									px: { xs: 20, sm: 40 },
									py: 10,
									my: "auto",
								}}
							>
								<UtilityBillIncreasing mt={0} yearlyBills={yearlyBills} />
							</Box>
						)}
						{displaySecondElement && (
							// <img
							// 	className="right-image fade-in-block"
							// 	src={getStreetViewImage(user.address) || HomeImage}
							// 	alt=""
							// 	style={{ objectFit: "cover" }}
							// />
							<img
								className="right-image fade-in-block"
								src={isSmallScreen ? getStreetViewImage(user.address) : getSatelliteImage(user.address)}
								alt=""
							/>
						)}
						{displayThirdElement && (
							<Box className="fade-in-block" sx={{ p: { xs: 20, sm: 40 }, my: "auto" }}>
								<Box display="flex" alignItems="flex-end" justifyContent="center" gap={{ xs: 20, sm: 70 }}>
									{data?.UtilityBillAfterSolar > 0 ? (
										<DoubleBarsWithValue
											height={heightOfResidualBlock}
											value={decimalFreeNumber(data?.totalResidualBill25Years)}
											subtext="Residual bill"
											height2={heightOfSavingsBlock}
											value2={decimalFreeNumber(data?.switchToSolar)}
											subtext2="Solar loan"
											heading="Switch to solar"
											totalValue={decimalFreeNumber(data?.electricAndResidualBill)}
										/>
									) : (
										<BarWithValue
											height={heightOfSavingsBlock}
											value={decimalFreeNumber(data?.switchToSolar)}
											subtext="Switch to solar"
											isSolar
										/>
									)}
									<BarWithValue
										height={heightOfElectricBlock}
										value={decimalFreeNumber(data?.totalAnnualBill20years)}
										subtext="Stay with electric"
									/>
									{/* <BarWithValue
										height={heightOfElectricBlock}
										value={decimalFreeNumber(data?.totalAnnualBill20years)}
										subtext="Stay with electric"
										height2={heightOfSavingsBlock}
										value2={decimalFreeNumber(data?.switchToSolar)}
										subtext2="Switch to solar"
									/> */}
								</Box>

								<Box
									display="flex"
									flexDirection="column"
									gap={24}
									mt={42}
									width={{ xs: "100%", sm: "100%" }}
									mx="auto"
								>
									{/* <DashedBoxWithValues
										leftValue={{ value: data?.solarSavingMonth1, text: "With Solar" }}
										rightValue={{ value: data?.monthlyBill, text: "Without Solar" }}
									/> */}
									{/* <DashedBoxWithValues
									leftValue={{ value: annualBill1month, text: "Annual utility bill" }}
									rightValue={{ value: annualBill20years, text: "Annual utility bill in 25 years" }}
								/> */}
									<DashedBoxWithValues
										isWhiteBox
										leftValue={{
											value: decimalFreeNumber(data?.solarSavingYear25) as any,
											// data?.trueSolarSaving25years
											text: "25 year cost of doing nothing",
										}}
									/>
								</Box>
							</Box>
						)}
					</SolarReportRightColumn>
				)}

				{isSmallScreen && (
					<Box className="fade-in-block" sx={{ p: { xs: 0, sm: 40 }, my: "auto" }}>
						<Box display="flex" alignItems="flex-end" justifyContent="center" gap={{ xs: 20, sm: 70 }}>
							{data?.UtilityBillAfterSolar > 0 ? (
								<DoubleBarsWithValue
									height={heightOfResidualBlock}
									value={decimalFreeNumber(data?.totalResidualBill25Years)}
									subtext="Residual bill"
									height2={heightOfSavingsBlock}
									value2={decimalFreeNumber(data?.switchToSolar)}
									subtext2="Solar loan"
									heading="Switch to solar"
									totalValue={decimalFreeNumber(data?.electricAndResidualBill)}
								/>
							) : (
								<BarWithValue
									height={heightOfSavingsBlock}
									value={decimalFreeNumber(data?.switchToSolar)}
									subtext="Switch to solar"
									isSolar
								/>
							)}
							<BarWithValue
								height={heightOfElectricBlock}
								value={decimalFreeNumber(data?.totalAnnualBill20years)}
								subtext="Stay with electric"
							/>
							{/* <BarWithValue
								height={heightOfElectricBlock}
								value={decimalFreeNumber(data?.totalAnnualBill20years)}
								subtext="Stay with electric"
								height2={heightOfSavingsBlock}
								value2={decimalFreeNumber(data?.switchToSolar)}
								subtext2="Switch to solar"
							/> */}
						</Box>

						<Box display="flex" flexDirection="column" gap={24} mt={42} width={{ xs: "100%", sm: "100%" }} mx="auto">
							{/* <DashedBoxWithValues
								leftValue={{ value: data?.solarSavingMonth1, text: "With Solar" }}
								rightValue={{ value: data?.monthlyBill, text: "Without Solar" }}
							/> */}
							<DashedBoxWithValues
								isWhiteBox
								leftValue={{
									value: decimalFreeNumber(data?.solarSavingYear25) as any,
									// data?.trueSolarSaving25years
									text: "25 year cost of doing nothing",
								}}
							/>
						</Box>
					</Box>
				)}
			</SolarReportContainer>

			{isSmallScreen &&
				(!forDashboard ? (
					<CustomButton
						fullWidth
						onClick={handleNext}
						sx={{ width: { xs: "calc(100% - 16px - 16px)", md: "calc(100% - 32px - 32px)" }, mx: 16, mb: 32 }}
					>
						{textSnippets.unlock_proposal_button || "See if I qualify"}
					</CustomButton>
				) : (
					<></>
					// <Box sx={{ width: "calc(100%)", mb: 32 }}>
					// 	<PDFDownloader
					// 		isSummaryPage
					// 		buttonText={textSnippets.download_proposal_button || "Download Summary Proposal"}
					// 		elementId="summary-report"
					// 	/>
					// </Box>
				))}

			<QualificationDialog open={showDialog} onClose={closeDialog} bill={user?.bill} />
		</>
	);
};

export default SolarReport;
