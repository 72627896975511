import RouteNavigation from "./Routes/RouteNavigation";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./ToastStyles.css";
import "chart.js/auto";
import { useSelector } from "./Redux/reduxHooks";
import { selectColors, selectFavicon, selectLoading, selectLoadingColors, selectSiteTitle } from "./Redux/Slices/generalSlice";
import createAppTheme from "./theme";
import { useEffect } from "react";

const App = () => {
	const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_ID ?? "";
	const colors = useSelector(selectColors);
	const theme = createAppTheme(colors);
	const title = useSelector(selectSiteTitle);
	const favicon = useSelector(selectFavicon);
	const loading = useSelector(selectLoading);
	const loadingColors = useSelector(selectLoadingColors);

	console.log("COLOR-APP", colors);

	console.log("TITLE-APP", title);
	console.log("FAVICON-APP", favicon);

	// useEffect to update the document title
	useEffect(() => {
		if (title)
			document.title = title;
		else
			document.title = '\u200B'
	}, [title]); // The effect depends on the `title` state

	// useEffect to update the favicon when it changes
	useEffect(() => {
		if (favicon)
			updateFavicon(favicon);
	}, [favicon]); // The effect depends on the `favicon` state

	// Function to update the favicon
	const updateFavicon = (iconURL: string): void => {
		let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;

		if (!link) {
			link = document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}

		link.href = iconURL;
	};

	if (loading || loadingColors) return <></>
	return (
		<GoogleOAuthProvider clientId={googleClientId}>
			<ThemeProvider theme={theme}>
				<RouteNavigation />
				<ToastContainer hideProgressBar autoClose={2500} />
			</ThemeProvider>
		</GoogleOAuthProvider>
	);
};

export default App;
