import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Slices/userSlice";
import surveySlice from "./Slices/surveySlice";
import textSnippetReducer, { fetchTextSnippets } from "./Slices/textSnippetsSlice";
import representativeSlice from "./Slices/representativeSlice";
import { fetchColors, fetchLogo, setJwtFromUrl } from "./Slices/generalSlice";
import generalSlice from "./Slices/generalSlice";

export const store = configureStore({
	reducer: {
		user: userSlice,
		representative: representativeSlice,
		survey: surveySlice,
		textSnippets: textSnippetReducer,
		general: generalSlice
	},
});
store.dispatch(setJwtFromUrl());
store.dispatch(fetchLogo());
store.dispatch(fetchTextSnippets());
store.dispatch(fetchColors());

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
